import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

// import Home from '../pages/Home';
import Pessoas from "../pages/Pessoas";
import Documentos from "../pages/Documentos";
import Boletos from "../pages/Boletos";
import AberturaChamados from "../pages/AberturaChamados";
import Perfil from "../pages/Perfil";
import ControleDeAcesso from "../pages/ControleDeAcesso";
import Subcontas from "../pages/Subcontas";
import { TermosUso } from "../pages/TermosUso";
import PaginasPage from "../pages/Paginas";
import PaginaPage from "../pages/Pagina";
import AuthContext from "../contexts/auth";

const AppRoutes: React.FC = () => {
  const verificaSubconta =
    !localStorage.getItem("@auth:subconta") ||
    localStorage.getItem("@auth:subconta") === "";
  const { recursos, fetchRecursos } = useContext(AuthContext);

  useEffect(() => {
    fetchRecursos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Route
        path="/"
        component={() =>
          verificaSubconta ? (
            <Redirect to={{ pathname: "/unidades" }} />
          ) : (
            <Redirect
              to={{
                pathname: recursos.includes("Bem-vindo")
                  ? "/paginas"
                  : recursos.includes("Documentos")
                  ? "/documentos"
                  : "/contatos",
              }}
            />
          )
        }
        exact={true}
      />
      <Route
        path="/contatos"
        component={
          verificaSubconta
            ? () => <Redirect to={{ pathname: "/unidades" }} />
            : Pessoas
        }
        exact={true}
      />
      <Route
        path="/boletos"
        component={
          verificaSubconta
            ? () => <Redirect to={{ pathname: "/unidades" }} />
            : Boletos
        }
        exact={true}
      />
      <Route
        path="/documentos"
        component={
          verificaSubconta
              ? () => <Redirect to={{ pathname: "/unidades" }} />
            : recursos.includes("Documentos")
            ? Documentos
            : () => <Redirect to={{ pathname: "/" }} />
        }
        exact={true}
      />
      <Route
        path="/paginas"
        component={
          verificaSubconta
            ? () => <Redirect to={{ pathname: "/unidades" }} />
            : recursos.includes("Bem-vindo")
            ? PaginasPage
            : () => <Redirect to={{ pathname: "/" }} />
        }
        exact={true}
      />
      <Route
        path="/paginas/:id"
        component={
          verificaSubconta
            ? () => <Redirect to={{ pathname: "/unidades" }} />
            : PaginaPage
        }
        exact={true}
      />
      <Route
        path="/abertura-de-chamados"
        component={
          verificaSubconta
            ? () => <Redirect to={{ pathname: "/unidades" }} />
            : AberturaChamados
        }
        exact={true}
      />
      <Route
        path="/perfil"
        component={
          verificaSubconta
            ? () => <Redirect to={{ pathname: "/unidades" }} />
            : Perfil
        }
        exact={true}
      />
      <Route
        path="/controle-de-acesso"
        component={
          verificaSubconta
            ? () => <Redirect to={{ pathname: "/unidades" }} />
            : ControleDeAcesso
        }
        exact={true}
      />
      <Route
        path="/subcontas"
        component={() => <Redirect to={{ pathname: "/unidades" }} />}
        exact={true}
      />
      <Route path="/unidades" component={Subcontas} exact={true} />
      <Route
        path="/cadastro"
        component={() => <Redirect to={{ pathname: "/" }} />}
        exact={true}
      />
      <Route path="/termos-de-uso" component={TermosUso} exact={true} />
    </>
  );
};

export default AppRoutes;
