import React from 'react';
import { HiUserGroup } from 'react-icons/hi';

import {IoIosPaper, IoMdPaper} from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Bem-vindo',
    path: '/paginas',
    icon: <IoMdPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Contatos',
    path: '/contatos',
    icon: <HiUserGroup />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Boletos',
  //   path: '/boletos',
  //   icon: <ImBarcode />,
  //   cName: 'nav-text'
  // },
  {
    title: 'Documentos',
    path: '/documentos',
    icon: <IoIosPaper />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Aberturas de chamados',
  //   path: '/abertura-de-chamados',
  //   icon: <FaHeadset />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Controle de Acesso',
  //   path: '/controle-de-acesso',
  //   icon: <FaIcons.FaTv />,
  //   cName: 'nav-text'
  // }
];